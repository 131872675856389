function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Tony's Chocolonely</h1>
        <p>
          A new unique chocolate made fresh for the South African market, launched in 2023 in Cape Town, South Africa.
        </p>
        <p>
        Contact us to learn more or distribute our amazing chocolate.
        </p>
        <div>
          <a
            className="App-link"
            href="mailto:contact@tonyschocolonely.co.za"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact@tonyschocolonely.co.za
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
